import Image from 'next/image';
import type React from 'react';
import { useEffect, useState } from 'react';

export const Hero = (): React.JSX.Element => {
  const [image, setImage] = useState('');
  useEffect(() => {
    const images = [
      // 'a429e6ca-60f6-4db8-8933-1fd594b8395b.jpg',
      // 'd9ddcf43-55f2-4b2a-a185-343f21962d65.jpg',
      // '143efce1-3ec0-4ff9-9057-152039b0add8.jpg',
      // '18356c33-2fb6-44c5-acfd-354c0ed52935.jpg',
      '5c7a5a7c-a71a-4d5b-a9b0-ee89d90e76f0.jpg',
      '6c3482d0-37d3-4d54-aab8-aed57d9eb89f.jpg',
      '6a5493f9-721b-4958-8d7f-c64ed957ac05.jpg',
      'cdfe4d49-52c0-4b0c-be20-cde195517b1b.jpg',
    ];
    setImage(images[Math.floor(Math.random() * images.length)]);
  }, []);
  return (
    <section className="relative flex h-[28rem] flex-col items-center justify-center bg-blue-600 sm:h-[32rem] md:h-[36rem] lg:h-[40rem] xl:h-[44rem]">
      {image && (
        <Image
          className="object-cover object-[50%_30%] contrast-150 grayscale"
          fill={true}
          src={process.env.NEXT_PUBLIC_STORAGE + image}
          alt=""
        />
      )}
      <div className="absolute inset-0 bg-blue-600/80" />
      <div className="relative px-3 pb-16 text-center text-white md:px-6 md:pb-24 lg:pb-32">
        <div className="text-sm font-medium tracking-wide uppercase sm:text-base md:text-lg">
          100 Jahre pure Leidenschaft
        </div>
        <h1 className="my-4 text-4xl leading-none font-bold sm:text-5xl md:my-5 md:text-6xl lg:my-8 lg:text-7xl">
          SV Gmundner Milch
        </h1>
        <p className="leading-relaxed text-white/60 md:text-lg lg:text-xl">
          Seit 1921 unverzichtbarer Bestandteil
          <br /> der Traunseestadt
        </p>
      </div>
    </section>
  );
};
