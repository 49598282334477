import Image from 'next/image';
import type React from 'react';
import { type TableEntry } from '../../types/table-entry';
import { Container } from '../container';
import { Logo } from '../logo';

export const Tables = ({ tableEntries }: { tableEntries: TableEntry[] }): React.JSX.Element => (
  <section className="bg-white py-24">
    <Container>
      <h2 className="mb-8 text-3xl font-bold sm:text-4xl">Tabellen</h2>
      <div className="grid grid-cols-1 gap-3 space-y-6 sm:grid-cols-2 sm:space-y-0 md:gap-6">
        <Table name="OÖ Liga" tableEntries={tableEntries.filter(x => x.group === 'first')} />
        <Table name="OÖ 2. Klasse Süd" tableEntries={tableEntries.filter(x => x.group === 'reserve')} />
      </div>
    </Container>
  </section>
);

export const Table = ({ name, tableEntries }: { name: string; tableEntries: TableEntry[] }): React.JSX.Element => (
  <div>
    <h3 className="mb-4 font-medium tracking-wide text-gray-500 uppercase">{name}</h3>
    <div className="w-full overflow-hidden rounded-xs shadow-sm ring-1 ring-black/5">
      <table className="block divide-y divide-gray-300 text-sm whitespace-nowrap">
        <thead className="block bg-gray-50 font-medium">
          <tr className="flex">
            <ThCenter>
              <abbr title="Rang">#</abbr>
            </ThCenter>
            <ThLeft>Mannschaft</ThLeft>
            <ThCenter>
              <abbr title="Spiele">Sp.</abbr>
            </ThCenter>
            <ThCenter>
              <abbr title="Siege">S</abbr>
            </ThCenter>
            <ThCenter>
              <abbr title="Unentschieden">U</abbr>
            </ThCenter>
            <ThCenter>
              <abbr title="Niederlagen">N</abbr>
            </ThCenter>
            <ThCenter>
              <abbr title="Punkte">P</abbr>
            </ThCenter>
          </tr>
        </thead>
        <tbody className="block divide-y divide-gray-200 bg-white">
          {tableEntries.map(({ rank, logo, name, games, wins, draws, losses, points }) => (
            <tr
              key={name}
              className={`flex ${name.startsWith('SV Gmunden') ? 'bg-blue-500 font-medium text-white' : ''}`}>
              <TdCenter>{rank}</TdCenter>
              <TdLeft>
                {name.startsWith('SV Gmunden') ? (
                  <Logo height="20px" width="20px" className="mr-2.5 inline h-5 w-5 shrink-0 object-contain" />
                ) : (
                  <span className="mr-2.5 inline h-5 w-5 shrink-0">
                    <Image
                      className="h-full w-full object-contain"
                      width={20}
                      height={20}
                      src={process.env.NEXT_PUBLIC_STORAGE + logo}
                      alt=""
                    />
                  </span>
                )}
                <span className="w-full truncate">{name}</span>
              </TdLeft>
              <TdCenter>{games}</TdCenter>
              <TdCenter>{wins}</TdCenter>
              <TdCenter>{draws}</TdCenter>
              <TdCenter>{losses}</TdCenter>
              <TdCenter>{points}</TdCenter>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const ThLeft = ({ children }: { children: React.ReactNode }): React.JSX.Element => (
  <th scope="col" className="block grow basis-72 p-2 text-left">
    {children}
  </th>
);

const ThCenter = ({ children }: { children: React.ReactNode }): React.JSX.Element => (
  <th scope="col" className="block w-8 shrink-0 grow basis-8 p-2 text-center">
    {children}
  </th>
);

const TdLeft = ({ children }: { children: React.ReactNode }): React.JSX.Element => (
  <td className="flex max-w-full grow basis-72 items-center truncate p-2 text-left">{children}</td>
);

const TdCenter = ({ children }: { children: React.ReactNode }): React.JSX.Element => (
  <td className="flex w-8 shrink-0 grow basis-8 items-center justify-center p-2 text-center">{children}</td>
);
