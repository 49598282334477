import Link from 'next/link';
import type React from 'react';
import { type Match } from '../../types/match';
import { Container } from '../container';
import { ArrowAnimatedIcon } from './arrow-animated-icon';
import { Club } from './club';

export const PastMatches = ({ matches }: { matches: Match[] }): React.JSX.Element => {
  if (matches.length === 0) {
    return <div className="-mt-24" />;
  }
  return (
    <section className="bg-gray-100">
      <Container>
        <div className="relative z-10 -mt-16 sm:-mt-24 lg:-mt-32">
          <div className="relative rounded-xs bg-white p-6 shadow-xl lg:flex">
            <div className="mb-8 flex items-center justify-between lg:mb-0 lg:w-44 lg:flex-col lg:items-start">
              <h2 className="text-xl font-bold sm:text-3xl">Letzte Spiele</h2>
              <Link href="#naechste-spiele" className="group inline-flex items-center text-blue-500 hover:underline">
                Nächste Spiele <ArrowAnimatedIcon />
              </Link>
            </div>
            <div className="grid grow gap-6 md:grid-cols-2 md:gap-0 md:divide-x">
              {matches.map((match, i) => (
                <PastMatch key={i} match={match} />
              ))}
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

const PastMatch = ({ match }: { match: Match }): React.JSX.Element => {
  return (
    <div className="shrink-0 md:first:pr-4 md:last:pl-4">
      <div className="pb-2 text-center text-xs font-medium tracking-wide text-gray-500 uppercase sm:text-sm">
        {match.type}
      </div>
      <div className="flex justify-evenly">
        <Club name={match.homeName} logo={match.homeLogo} />
        <div className="flex w-16 justify-center self-center text-center text-3xl font-bold whitespace-nowrap sm:w-24 sm:text-4xl">
          {`${match.homeGoals ?? '-'} : ${match.guestGoals ?? '-'}`}
        </div>
        <Club name={match.guestName} logo={match.guestLogo} />
      </div>
    </div>
  );
};
