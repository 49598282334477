import Image from 'next/image';
import Link from 'next/link';
import type React from 'react';
import { Container } from '../container';
import { ArrowAnimatedIcon } from './arrow-animated-icon';

export const Partners = (): React.JSX.Element => (
  <section className="bg-gray-100 py-24">
    <Container>
      <h2 className="mb-8 text-3xl font-bold sm:text-4xl">Partner</h2>
      <div className="grid gap-6 md:grid-cols-2">
        <Partner image={process.env.NEXT_PUBLIC_STORAGE + 'f477e378-5674-45c1-8d02-cf282348423d.svg'}>
          <h3 className="text-2xl font-bold">RIESPO</h3>
          <p className="font-medium">Großes Netzwerk, große Vorteile</p>
          <p className="">
            Vereine, Spieler und Trainer – vom Amateur bis Profifußball – nutzen die globale Datenbank von RIESPO für
            Scouting, fürs Management und zum Networking um ihre Performance zu verbessern.
          </p>
          <Link
            href="https://riespo.com"
            className="group inline-flex items-center text-blue-500 hover:underline"
            target="_blank"
            rel="nofollow">
            RIESPO.com besuchen <ArrowAnimatedIcon />
          </Link>
        </Partner>
        <Partner image={process.env.NEXT_PUBLIC_STORAGE + '4f06588c-6d34-4358-95ee-a971a87ca43b.svg'}>
          <h3 className="text-2xl font-bold">CAMPUS</h3>
          <p className="font-medium">Mein Talent, mein Fußball, meine Lehre</p>
          <p>
            Vernetze Fussball und Lehre und nütze deine Chance bei Campus Partnerbetrieben deinen Lehrberuf zu finden.
            Partnerbetriebe tragen unser CAMPUS Gütesiegel. Infos: Hubert Aigner,{' '}
            <Link
              className="text-blue-500 hover:underline"
              href="mailto:hubert.aigner@traunseenet.at"
              target="_blank"
              rel="nofollow">
              hubert.aigner@traunseenet.at
            </Link>
            ,{' '}
            <Link className="text-blue-500 hover:underline" href="tel:+436642621417" target="_blank" rel="nofollow">
              +43 664 2621417
            </Link>
            .
          </p>
          <Link
            href={process.env.NEXT_PUBLIC_STORAGE + '3f6f9b5e-a5e0-42ce-a1b1-7d8c2bb5a3f7.pdf'}
            className="group inline-flex items-center text-blue-500 hover:underline"
            target="_blank"
            rel="nofollow">
            Campus Folder <ArrowAnimatedIcon />
          </Link>
        </Partner>
      </div>
    </Container>
  </section>
);

export const Partner = ({ image, children }: { image: string; children: React.ReactNode }): React.JSX.Element => (
  <div className="rounded-xs bg-white p-6 leading-relaxed shadow-sm">
    <span className="float-right mb-2 ml-2 inline-block h-24 w-24 [shape-outsied:circle()]">
      <Image className="h-full w-full object-contain" width={96} height={96} src={image} alt="" />
    </span>
    <div className="space-y-4">{children}</div>
  </div>
);
