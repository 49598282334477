import Image from 'next/image';
import type React from 'react';
import { Logo } from '../logo';

export const Club = ({ name, logo }: { name: string; logo: string }): React.JSX.Element => (
  <div className="w-28 space-y-2">
    <div className="relative mx-auto h-16 w-16">
      {name.startsWith('SV Gmunden') ? (
        <Logo className="object-contain" />
      ) : (
        <Image
          className="h-full w-full object-contain"
          width={64}
          height={64}
          src={process.env.NEXT_PUBLIC_STORAGE + logo}
          alt=""
        />
      )}
    </div>
    <div className="line-clamp-2 h-8 overflow-hidden text-center text-xs font-medium break-words">{name}</div>
  </div>
);
