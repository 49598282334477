import Image from 'next/image';
import Link from 'next/link';
import type React from 'react';
import { type TeamInfo } from '../../types/team-info';
import { Container } from '../container';
import { Pattern } from '../pattern';
import { ArrowAnimatedIcon } from './arrow-animated-icon';

export const TeamInfos = ({ teamInfos }: { teamInfos: TeamInfo[] }): React.JSX.Element => {
  const sizes1 = '(min-width: 1024px) 516px, (min-width: 768px) 50vw, 100vw';
  const sizes2 = '(min-width: 1024px) 261px, 50vw';
  return (
    <section className="relative bg-gray-100 py-24">
      <Pattern className="absolute inset-0 h-full w-full text-black/20" />
      <Container>
        <h2 className="mb-8 text-3xl font-bold sm:text-4xl">Mannschaften</h2>
        <div className="rounded-xs sm:bg-white sm:p-6 sm:shadow-xl">
          <div className="grid gap-1 pb-1 md:grid-cols-2 lg:gap-6 lg:pb-8">
            <TeamImage sizes={sizes1} teamInfo={teamInfos.find(x => x.name === 'Kampfmannschaft')} />
            <TeamImage sizes={sizes1} teamInfo={teamInfos.find(x => x.name === 'Juniors')} />
          </div>
          <div className="grid grid-cols-2 gap-1 lg:grid-cols-4">
            <TeamImage sizes={sizes2} teamInfo={teamInfos.find(x => x.name === 'Frauen')} />
            <TeamImage sizes={sizes2} teamInfo={teamInfos.find(x => x.name === 'U18')} />
            <TeamImage sizes={sizes2} teamInfo={teamInfos.find(x => x.name === 'U16')} />
            <TeamImage sizes={sizes2} teamInfo={teamInfos.find(x => x.name === 'U15')} />
            <TeamImage sizes={sizes2} teamInfo={teamInfos.find(x => x.name === 'U14')} />
            <TeamImage sizes={sizes2} teamInfo={teamInfos.find(x => x.name === 'U13')} />
            <TeamImage sizes={sizes2} teamInfo={teamInfos.find(x => x.name === 'U12')} />
            <TeamImage sizes={sizes2} teamInfo={teamInfos.find(x => x.name === 'U11')} />
            <TeamImage sizes={sizes2} teamInfo={teamInfos.find(x => x.name === 'U10 A')} />
            <TeamImage sizes={sizes2} teamInfo={teamInfos.find(x => x.name === 'U10 B')} />
            <TeamImage sizes={sizes2} teamInfo={teamInfos.find(x => x.name === 'U08')} />
            <TeamImage sizes={sizes2} teamInfo={teamInfos.find(x => x.name === 'U07')} />
          </div>
        </div>
      </Container>
    </section>
  );
};

export const TeamImage = ({ teamInfo, sizes }: { teamInfo?: TeamInfo; sizes: string }): React.JSX.Element => {
  if (teamInfo === undefined) {
    return <></>;
  }
  return (
    <Link
      href={'/teams/' + teamInfo.slug}
      className="group relative block overflow-hidden rounded-xs bg-blue-600 shadow-sm">
      <div className="pt-[66.66%]" />
      {teamInfo.image && (
        <Image
          className="object-cover transition-transform duration-500 group-hover:scale-105"
          fill={true}
          sizes={sizes}
          src={process.env.NEXT_PUBLIC_STORAGE + teamInfo.image}
          alt=""
        />
      )}
      <div className="absolute inset-0 top-1/2 bg-linear-to-t from-blue-600/70 to-transparent" />
      {teamInfo.name && (
        <div className="absolute bottom-4 left-4 inline-flex items-center text-xl font-bold text-white group-hover:underline">
          {teamInfo.name} <ArrowAnimatedIcon />
        </div>
      )}
    </Link>
  );
};
