import Link from 'next/link';
import type React from 'react';
import { type NewsInfo } from '../../types/news-info';
import { Container } from '../container';
import { Item } from '../news/item';
import { Pattern } from '../pattern';
import { ArrowAnimatedIcon } from './arrow-animated-icon';

export const LatestNews = ({ newsInfos }: { newsInfos: NewsInfo[] }): React.JSX.Element => {
  return (
    <section className="relative bg-gray-100 py-24">
      <Pattern className="text-blue absolute inset-0 hidden h-full w-full lg:block" />
      <div className="absolute inset-0 hidden bg-linear-to-t from-transparent to-gray-100 lg:block" />
      <Container>
        <div className="mb-8 flex items-center justify-between">
          <h2 className="text-3xl font-bold sm:text-4xl">Neueste Artikel</h2>
          <Link href="/news" className="group inline-flex items-center text-blue-500 hover:underline">
            Alle News <ArrowAnimatedIcon />
          </Link>
        </div>
        <ul className="grid gap-3 sm:grid-cols-2 md:gap-6 lg:grid-cols-3">
          {newsInfos.map(newsInfo => (
            <li key={newsInfo.slug}>
              <Item newsInfo={newsInfo} />
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
};
